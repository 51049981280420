import { Injectable } from "@angular/core";
import { DataService } from './data.service';
import { IUserAuthData } from "src/app/model/dto/user.authdata.interface";
import { IUserLogin } from "src/app/model/dto/user.login.interface";
import { IUserRegister } from "src/app/model/dto/user.register.interface";
import { AppService } from "./app.service";
import { IUser } from "src/app/model/entities/user.interface";
import { CookieService } from "./cookie.service";

@Injectable()
export class AuthService {    
    public user: IUser = null;

    constructor(
        private dataService: DataService,
        private appService: AppService,
        private cookieService: CookieService,
    ) 
    {
        const data = this.cookieService.getItem("authdata");
        data && this.init(JSON.parse(data));   
    }

    get authData(): IUserAuthData {return this.dataService.authData;}  
    set authData(v: IUserAuthData) {this.dataService.authData = v;}  
    
    private async init(data: IUserAuthData): Promise<void> {  
        try {
            this.authData = data;
            this.user = await this.loadUser(this.authData.id);
        } catch (err) {
            this.appService.notifyError(err);
        }        
    }    
        
    public logout(): void {        
        this.authData = null;    
        this.cookieService.removeItem("authdata");                 
    }

    private save(): void {        
        this.cookieService.setItem("authdata", JSON.stringify(this.authData));
    }

    private loadUser(id: number): Promise<IUser> {
        return new Promise((resolve, reject) => 
            this.dataService
                .usersOne(id)
                .subscribe({
                    next: res => res.statusCode === 200 ? resolve(res.data) : reject(res.error), 
                    error: err => reject(err.message),
                }));
    }  

    public login(dto: IUserLogin): Promise<number> {
        return new Promise((resolve, reject) => 
            this.dataService
                .usersLogin(dto)
                .subscribe({
                    error: err => reject(err.message),
                    next: res => {                
                        if (res.statusCode === 200) {    
                            this.init(res.data);             
                            this.save();                                        
                        }
    
                        resolve(res.statusCode);
                    }}));        
    }

    public register(dto: IUserRegister): Promise<number> {
        return new Promise((resolve, reject) => 
            this.dataService
                .usersRegister(dto)
                .subscribe({
                    error: err => reject(err.message),
                    next: res => resolve(res.statusCode),    
                }));        
    }
}
