import { Component } from "@angular/core";
import { PopupComponent } from "../popup.component";

@Component({
    selector: "popup-logout",
    templateUrl: "popup-logout.component.html",
    styleUrls: [
        "../popup.component.scss",
        "popup-logout.component.scss",
    ],
})
export class PopupLogoutComponent extends PopupComponent {}
