import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { ICard } from 'src/app/model/entities/card.interface';
import { IGetList } from 'src/app/model/dto/getlist.interface';

@Injectable()
export class CardRepository {    
    constructor(protected dataService: DataService) {}

    public loadAll(sortBy: string = "id", sortDir: number = 1, filter: any = {}): Promise<ICard[]> {    
        const dto: IGetList = {sortBy, sortDir, filter};    
        return new Promise((resolve, reject) =>             
            this.dataService
                .cardsAll(dto)
                .subscribe({
                    next: res => res.statusCode === 200 ? resolve(res.data) : reject(res.error), 
                    error: err => reject(err.message),
                }));
    }    
}
