<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['credit']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content compact">
            <div class="b-margined-20 "><the-card [card]="card"></the-card></div>
            <form class="gf" (submit)="onSubmit()">
                <div class="gf-row b-margined-20">
                    <div class="gf-title b-margined-5">{{words['credit']?.['amount']?.[lang.slug]}}</div>
                    <div class="gf-inputwrap"><input type="number" name="amount" min="0" [(ngModel)]="amount" [class.error]="errors['amount']" [placeholder]="words['credit']?.['ph-amount']?.[lang.slug]"></div>
                    <div *ngIf="errors['amount']" class="gf-error">{{words['errors']?.[errors['amount']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-50">
                    <div class="gf-title b-margined-5">{{words['credit']?.['period']?.[lang.slug]}}</div>
                    <the-period [(value)]="period"></the-period>
                </div>
                <div class="gf-row">
                    <div class="gf-btnwrap"><button type="submit" class="btn" [class.busy]="loading"><span>{{words['credit']?.['submit']?.[lang.slug]}}</span></button></div>
                </div>   
            </form>
        </div>
    </div>
</div>
