import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ILang } from "src/app/model/entities/lang.interface";
import { IWords } from "src/app/model/entities/words.interface";
import { AppService } from "../../services/app.service";
import { AuthService } from "../../services/auth.service";

@Directive()
export abstract class MMComponent implements OnChanges {
    @Input() public active: boolean = false;
    @Output() private activeChange: EventEmitter<boolean> = new EventEmitter();

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
    ) {}

    get words(): IWords {return this.appService.words;}      
    get lang(): ILang {return this.appService.lang;}  
    get url(): string[] {return this.appService.url;}
    get authenticated(): boolean {return this.authService.authData !== null;}

    public ngOnChanges(changes: SimpleChanges): void {
        const f = changes["active"].currentValue ? "add" : "remove";
        this.appService.win.classList[f]("inactive");
    }

    public close(): void {
        this.activeChange.emit(false);
    }
}
