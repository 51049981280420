<header [class.opaque]="opaque || mmMainActive || mmAccountActive">
    <div class="h-content-desktop">
        <div class="h-logo">
            <div class="h-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.png" alt="logo"></a></div>
        <lang-dropdown></lang-dropdown>
        </div>
        <nav class="h-menu">
            <ul>
                <li><a (click)="onMenuClick('calculator')">{{words['header']?.['calculator']?.[lang.slug]}}</a></li>
                <li><a (click)="onMenuClick('crypto')">{{words['header']?.['crypto']?.[lang.slug]}}</a></li>
            </ul>
        </nav>
        <div class="h-account">
            <ng-container *ngIf="authenticated">
                <div class="ha-links">
                    <div routerLink="/{{lang.slug}}/account" class="smallbtn account" [class.active]="url[2] === 'account' && !url[3]"></div>
                </div>
                <div class="ha-notifications">
                    <div class="smallbtn notifications" (click)="pNotificationsActive = true" [class.active]="pNotificationsActive"></div>
                    <panel-notifications [(active)]="pNotificationsActive"></panel-notifications>
                </div>
                <div class="ha-links">
                    <div (click)="pLogoutActive = true" class="smallbtn logout" [class.active-red]="pLogoutActive"></div>
                    <panel-logout [(active)]="pLogoutActive"></panel-logout>
                </div>
            </ng-container>
            <ng-container *ngIf="!authenticated">
                <div class="ha-links">
                    <button class="btn outline ha-btn" (click)="pLoginActive = true"><span>{{words['header']?.['login']?.[lang.slug]}}</span></button>
                </div>
            </ng-container>            
        </div>
    </div>   
    <div class="h-content-mobile">
        <div class="h-content-container">
            <div class="smallbtn" [class.menu]="!mmMainActive" [class.close]="mmMainActive" (click)="toggleMMMain()"></div>
            <div class="h-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.png" alt="logo"></a></div>
        </div>

        <div class="h-account">
            <ng-container *ngIf="authenticated">
                <div class="ha-links">
                    <div routerLink="/{{lang.slug}}/account" class="smallbtn account" [class.active]="url[2] === 'account' && !url[3]"></div>
                </div>
                <div class="ha-notifications">
                    <div class="smallbtn notifications" routerLink="/{{lang.slug}}/account/notifications" [class.active]="pNotificationsActive"></div>
                    <panel-notifications [(active)]="pNotificationsActive"></panel-notifications>
                </div>
                <div class="ha-links">
                    <div (click)="pLogoutActive = true" class="smallbtn logout" [class.active-red]="pLogoutActive"></div>
                    <panel-logout [(active)]="pLogoutActive"></panel-logout>
                </div>
            </ng-container>
            <ng-container *ngIf="!authenticated">
                <button class="btn outline ha-btn" (click)="pLoginActive = true"><span>{{words['header']?.['login']?.[lang.slug]}}</span></button>
            </ng-container>            
        </div>
    </div> 
    <mm-main [(active)]="mmMainActive" (menuClick)="onMenuClick($event)"></mm-main>
    <mm-account [(active)]="mmAccountActive"></mm-account>
</header>
