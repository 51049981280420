import { Injectable } from '@angular/core';
import { Chunk } from 'src/app/model/dto/chunk';
import { IGetList } from 'src/app/model/dto/getlist.interface';
import { INotification } from 'src/app/model/entities/notification.interface';
import { DataService } from '../data.service';

@Injectable()
export class NotificationRepository {
    constructor(protected dataService: DataService) {}  
    
    public loadChunk(part: number = 0, chunkLength: number = 10, sortBy: string = "id", sortDir: number = 1, filter: any = {}): Promise<Chunk<INotification>> {
        const dto: IGetList = {from: part * chunkLength, q: chunkLength, sortBy, sortDir, filter};        
        return new Promise((resolve, reject) => 
            this.dataService
                .notificationsChunk(dto)
                .subscribe({
                    next: res => res.statusCode === 200 ? resolve(new Chunk(res.data, res.elementsQuantity, res.pagesQuantity)) : reject(res.error), 
                    error: err => reject(err.message),
                }));
    }
}
