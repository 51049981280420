import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { NotificationRepository } from "src/app/common/services/repositories/notification.repository";
import { INotification } from "src/app/model/entities/notification.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { PanelComponent } from "../panel.component";

@Component({
    selector: "panel-notifications",
    templateUrl: "panel-notifications.component.html",
    styleUrls: [
        "../panel.component.scss",
        "panel-notifications.component.scss",
    ],
})
export class PanelNotificationsComponent extends PanelComponent implements OnInit {
    @ViewChild("container", {static: false}) containerRef: ElementRef; 

    public notifications: INotification[] = [];
    public sortBy: string = "created_at";
    public sortDir: number = -1;
    public loadingMore: boolean = false;     
    public part: number = 0;
    public chunkLength: number = 20;
    public exhausted: boolean = false;   
    private started_at: Date = null;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected notificationRepository: NotificationRepository,
    ) 
    {
        super(appService);
    }

    get user_id(): number {return this.authService.authData.id;}
    get container(): HTMLElement {return this.containerRef.nativeElement;}
    get scrolledToBottom(): boolean {return this.container.scrollHeight - this.container.scrollTop < this.container.clientHeight + 200;}
    get canLoadMore(): boolean {return !this.loadingMore && !this.exhausted && this.scrolledToBottom;}  

    public ngOnInit(): void {
        this.initNotifications();
    }

    private async initNotifications(): Promise<void> {
		try {
            const filter = {user_id: this.user_id};
            // Для предотвращения дублей в бесконечной прокрутке при добавлении новых элементов после момента, когда первый кусок загружен.
            // Если сразу вставить в фильтр created: this.started_at, в серверной версии была бы проблема из-за другой таймзоны.
            this.started_at = new Date(); 
            const chunk = await this.notificationRepository.loadChunk(this.part, this.chunkLength, this.sortBy, this.sortDir, filter);   
            this.notifications = chunk.data;            
            this.exhausted = !chunk.elementsQuantity || this.part + 1 === chunk.pagesQuantity;  
		} catch (err) {
			this.appService.notifyError(err);
		}
	}

    public async onScroll(): Promise<void> {
        try {			            
            if (this.canLoadMore) {
				this.loadingMore = true;
				this.part++;     
                const filter = {user_id: this.user_id, created_at: this.started_at};                 
                const chunk = await this.notificationRepository.loadChunk(this.part, this.chunkLength, this.sortBy, this.sortDir, filter);  
                this.notifications = [...this.notifications, ...chunk.data];                                         
                this.exhausted = !chunk.elementsQuantity || this.part + 1 === chunk.pagesQuantity;  
				this.loadingMore = false;		                
			}			
		} catch (err) {
			this.appService.notifyError(err);
            this.loadingMore = false;
		}
    }
}