import { NgModule } from "@angular/core";
import { NgModel } from "@angular/forms";
import { TrimDirective } from "./trim.directive";

@NgModule({
    imports: [
        
    ],
    declarations: [     
        TrimDirective,
    ],
    exports: [   
        TrimDirective,
    ],    
    providers: [
        NgModel,
    ]
})
export class DirectivesModule {}
