import { Component, Input } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { TransactionRepository } from "src/app/common/services/repositories/transaction.repository";
import { ITransactionCreate } from "src/app/model/dto/transaction.create.interface";
import { ICard } from "src/app/model/entities/card.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { TerminalComponent } from "../terminal.component";

@Component({
    selector: "cards-terminal",
    templateUrl: "cards-terminal.component.html",
    styleUrls: [
        "../terminal.component.scss",
        "../../../../../common/styles/forms.scss",
    ],
})
export class CardsTerminalComponent extends TerminalComponent {
    @Input() cards: ICard[];
    public current: number = 0;
    public ca_no: string = "";
    public value: number = null;
    public errors: IKeyValue<string> = {};
    public loading: boolean = false;    
    
    get card(): ICard {return this.cards[this.current];}

    constructor(
        protected appService: AppService,
        protected transactionRepository: TransactionRepository,
    ) 
    {
        super(appService);
    }

    private validate(): boolean {
        console.log(this.ca_no);
        console.log(this.value);
        let error = false;

        if (!this.ca_no) {
            this.errors["ca_no"] = "required";
            error = true;
        } else {
            this.errors["ca_no"] = null;
        }

        if (typeof this.value !== 'number' || this.value < 0) {
            this.errors["value"] = "amount";
            error = true;
        } else if (this.value > this.card.balance) {
            this.errors["value"] = "insufficient";
            error = true;
        } else {
            this.errors["value"] = null;
        }

        return !error;
    }

    public async onSubmit(): Promise<boolean | void> {
        try {
            if (!this.validate()) {
                return;
            }

            this.loading = true;
            const dto: ITransactionCreate = {card_id: this.card.id, value: -this.value, ca_no: this.ca_no};
            const statusCode = await this.transactionRepository.create(dto);
            this.loading = false;
            this.reset();

            if (statusCode === 201) {
                return false;
            } else {
                this.appService.notifyError(this.words['errors']?.['common']?.[this.lang.slug]);
            } 
            return false;
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    } 

    public copy(txt: string): void {
        console.log('copy', this.card);
        window.navigator.clipboard.writeText(txt);
    }

    private reset(): void {
        this.ca_no = "";
        this.value = null;
    }
}
