import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../app.service';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthGuard {    
    constructor (
        private authService: AuthService,
        private appService: AppService,
        private router: Router,
    ) {}

    public canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {  
        if (this.authService.authData) {
            return true;
        }     

        this.router.navigateByUrl ("/");
        return false;
    }    
}
