import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { PaysystemRepository } from "src/app/common/services/repositories/paysystem.repository";
import { IPaysystem } from "src/app/model/entities/paysystem.interface";

@Component({
    selector: "list-paysystems",
    templateUrl: "list-paysystems.component.html",
    styleUrls: ["list-paysystems.component.scss"],
})
export class ListPaysystemsComponent implements OnInit {
    public paysystems: IPaysystem[] = [];

    constructor(
        protected paysystemRepository: PaysystemRepository,
        protected appService: AppService,
    ) {}

    public ngOnInit(): void {
        this.initPaysystems();
    }

    protected async initPaysystems(): Promise<void> {
        try {
            this.paysystems = await this.paysystemRepository.loadAll();
        } catch (err) {
            this.appService.notifyError(err);
        }
    }
}