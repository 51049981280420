<!-- head -->
<div class="home-head">
    <div class="hh-wrap">
        <div class="hh-subwrap">
            <div class="hh-content">
                <h1 class="hh-title" [innerHTML]="words['home']?.['head-title']?.[lang.slug]"></h1>
                <p class="hh-suptitle">{{words['home']?.['suptitle']?.[lang.slug]}}</p>
                <div class="hh-btns">
                    <button class="btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn1']?.[lang.slug]}}</span></button>
                    <button class="btn outline" (click)="onLoginBtnClick()"><span>{{words['home']?.['head-btn2']?.[lang.slug]}}</span></button>
                </div>
                <h3>{{words['home']?.['suptitle2']?.[lang.slug]}}</h3>
                <p class="hh-suptitle2">{{words['home']?.['suptitle-text2-1']?.[lang.slug]}}</p>
                <p class="hh-suptitle2">{{words['home']?.['suptitle-text2-2']?.[lang.slug]}}</p>
                <p class="hh-suptitle2">{{words['home']?.['suptitle-text2-3']?.[lang.slug]}}</p>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <!-- advantages -->
    <section>
        <div class="home-advantages">        
            <div class="had-item">
                <img class="had-icon" src="/assets/images/had-clock.svg" width="127" height="115" style="margin: 0 0 36px 0">
                <div class="had-content">
                    <div class="had-title">{{words['home']?.['advantages-title1']?.[lang.slug]}}</div>
                    <div class="had-text">{{words['home']?.['advantages-text1']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="had-item">
                <img class="had-icon" src="/assets/images/had-secure.svg" width="97" height="107" style="margin: 0 0 42px 0">
                <div class="had-content">
                    <div class="had-title">{{words['home']?.['advantages-title2']?.[lang.slug]}}</div>
                    <div class="had-text">{{words['home']?.['advantages-text2']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="had-item">
                <img class="had-icon" src="/assets/images/had-percent.svg" width="130" height="141" style="margin: -24px 0 32px 0">
                <div class="had-content">
                    <div class="had-title">{{words['home']?.['advantages-title3']?.[lang.slug]}}</div>
                    <div class="had-text">{{words['home']?.['advantages-text3']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="had-item">
                <img class="had-icon" src="/assets/images/had-headphones.svg" width="124" height="106" style="margin: 0 0 42px 0">
                <div class="had-content">
                    <div class="had-title">{{words['home']?.['advantages-title4']?.[lang.slug]}}</div>
                    <div class="had-text">{{words['home']?.['advantages-text4']?.[lang.slug]}}</div>
                </div>
            </div>
        </div>
    </section>
    <!-- about -->
    <section class="home-section">
        <h2 class="about-title" [innerHTML]="words['home']?.['about-title']?.[lang.slug]"></h2>
        <div class="about-text-wrapper">
            <p class="about-text">{{words['home']?.['about-text']?.[lang.slug]}}</p>
        </div>
        <div class="home-about">        
            <div class="ha-box">
                <div class="ha-title">{{words['home']?.['about-box-title1']?.[lang.slug]}}</div>
                <div class="ha-box-text">{{words['home']?.['about-box-text1']?.[lang.slug]}}</div>
                <img class="ha-icon" src="/assets/images/home-about1.svg">
            </div>
            <div class="ha-box">
                <div class="ha-title">{{words['home']?.['about-box-title2']?.[lang.slug]}}</div>
                <div class="ha-box-text" [innerHTML]="words['home']?.['about-box-text2']?.[lang.slug]"></div>
                <img class="ha-icon" src="/assets/images/home-about2.svg">
            </div>
            <div class="ha-box">
                <div class="ha-title">{{words['home']?.['about-box-title4']?.[lang.slug]}}</div>
                <div class="ha-box-text">{{words['home']?.['about-box-text4']?.[lang.slug]}}</div>
                <img class="ha-icon" src="/assets/images/home-about3.svg">
            </div>
            <div class="ha-box">
                <div class="ha-title">{{words['home']?.['about-box-title3']?.[lang.slug]}}</div>
                <div class="ha-box-text">{{words['home']?.['about-box-text3']?.[lang.slug]}}</div>
                <img class="ha-icon" src="/assets/images/home-about4.svg">
            </div>
        </div>
        <div class="about-text-wrapper-bottom">
            <p class="about-text">{{words['home']?.['about-text2']?.[lang.slug]}}</p>
        </div>
    </section>
    <!-- calculator -->
    <section>
        <div class="home-calc">
            <div class="hc-numbers">
                <h2 class="hc-title1" [innerHTML]="words['home']?.['calc-title1']?.[lang.slug]"></h2>
                <div class="hc-more-than">
                    <div class="hc-item">
                        <div class="hc-mt-top">{{words['home']?.['more-than-top']?.[lang.slug]}}</div>
                        <div class="hc-mt-content">{{words['home']?.['more-than1']?.[lang.slug]}}</div>
                        <div class="hc-mt-bottom">{{words['home']?.['more-than-sup1']?.[lang.slug]}}</div>
                    </div>
                    <div class="hc-divider"></div>
                    <div class="hc-item">
                        <div class="hc-mt-top">{{words['home']?.['more-than-top']?.[lang.slug]}}</div>
                        <div class="hc-mt-content">{{words['home']?.['more-than2']?.[lang.slug]}}</div>
                        <div class="hc-mt-bottom">{{words['home']?.['more-than-sup2']?.[lang.slug]}}</div>
                    </div>
                    <div class="hc-divider"></div>
                    <div class="hc-item">
                        <div class="hc-mt-top">{{words['home']?.['more-than-top']?.[lang.slug]}}</div>
                        <div class="hc-mt-content">{{words['home']?.['more-than3']?.[lang.slug]}}</div>
                        <div class="hc-mt-bottom">{{words['home']?.['more-than-sup3']?.[lang.slug]}}</div>
                    </div>
                    <div class="hc-divider"></div>
                    <div class="hc-item">
                        <div class="hc-mt-top">{{words['home']?.['more-than-top']?.[lang.slug]}}</div>
                        <div class="hc-mt-content">{{words['home']?.['more-than4']?.[lang.slug]}}</div>
                        <div class="hc-mt-bottom">{{words['home']?.['more-than-sup4']?.[lang.slug]}}</div>
                    </div>
                </div>
            </div>
            <div class="hc-bottom" name="calculator">
                <div class="hc-left">
                    <div class="circle"></div>
                    <div class="triangle"></div>
                    <div class="rhomb"></div>
                    <the-calculator></the-calculator>
                    <div class="alert">
                        <img src="/assets/images/Danger Circle.svg" alt="">
                        <span>{{words['home']?.['hc-alert']?.[lang.slug]}}</span>
                    </div>
                    <button class="btn hc-btn-mob" (click)="onPromoBtnClick()"><span>{{words['home']?.['power-button']?.[lang.slug]}}</span></button>
                </div>
                <div class="hc-right">
                    <h2 class="hc-title1" [innerHTML]="words['home']?.['calc-title2']?.[lang.slug]"></h2>
                    <div class="hc-text">{{words['home']?.['calc-text']?.[lang.slug]}}</div>
                    <button class="btn hc-btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['power-button']?.[lang.slug]}}</span></button>
                </div>
            </div>
        </div>
    </section>
</div>
    <!-- guide -->
    <section>
        <div class="home-guide">
            <div class="content">
                <h2 class="hg-title" [innerHTML]="words['home']?.['guide-title']?.[lang.slug]"></h2>
                <div class="hg-steps">
                    <div class="hg-item">
                        <div class="hg-head">
                            <img class="hg-icon" src="/assets/images/hg1.svg">
                            <div class="hg-item-title">{{words['home']?.['guide-item-title1']?.[lang.slug]}}</div>
                        </div>
                        <div class="hg-text" [innerHTML]="words['home']?.['guide-item-text1']?.[lang.slug]"></div>
                    </div>
                    <div class="hg-item">
                        <div class="hg-head">
                            <img class="hg-icon" src="/assets/images/hg2.svg">
                            <div class="hg-item-title">{{words['home']?.['guide-item-title2']?.[lang.slug]}}</div>
                        </div>
                        <div class="hg-text" [innerHTML]="words['home']?.['guide-item-text2']?.[lang.slug]"></div>
                    </div>
                    <div class="hg-item">
                        <div class="hg-head">
                            <img class="hg-icon" src="/assets/images/hg3.svg">
                            <div class="hg-item-title">{{words['home']?.['guide-item-title3']?.[lang.slug]}}</div>
                        </div>
                        <div class="hg-text" [innerHTML]="words['home']?.['guide-item-text3']?.[lang.slug]"></div>
                    </div>
                    <div class="hg-item">
                        <div class="hg-head">
                            <img class="hg-icon" src="/assets/images/hg4.svg">
                            <div class="hg-item-title">{{words['home']?.['guide-item-title4']?.[lang.slug]}}</div>
                        </div>
                        <div class="hg-text" [innerHTML]="words['home']?.['guide-item-text4']?.[lang.slug]"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<div class="content">
    <!-- converter -->
    <section name="crypto">        
        <div class="home-converter">
            <div class="hc-left">
                <the-converter></the-converter>
            </div>
            <div class="hc-right">
                <h2>{{words['home']?.['converter-title']?.[lang.slug]}}</h2>
                <div class="hc-text">{{words['home']?.['converter-suptitle']?.[lang.slug]}}</div>
            </div>
        </div>
    </section>
</div>
<!-- account -->
<section class="account-section">
    <div class="home-account">
        <img class="eth-1" src="/assets/images/eth-1.png" alt="">
        <img class="eth-2" src="/assets/images/eth-2.png" alt="">
        <img class="eth-mob" src="/assets/images/eth-mob.png" alt="">
        <div class="content">
            <div class="ha-container">
                <h2 class="ha-title">{{words['home']?.['open-account']?.[lang.slug]}}</h2>
                <button class="btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['power-button']?.[lang.slug]}}</span></button>
            </div>
        </div>
    </div>
</section>
<img class="glow-account" src="/assets/images/glow.png" alt="">
