import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { TransactionRepository } from "src/app/common/services/repositories/transaction.repository";
import { ICard } from "src/app/model/entities/card.interface";
import { ILang } from "src/app/model/entities/lang.interface";
import { ITransaction } from "src/app/model/entities/transaction.interface";
import { IWords } from "src/app/model/entities/words.interface";

@Component({
    selector: "list-transactions",
    templateUrl: "list-transactions.component.html",
    styleUrls: ["list-transactions.component.scss"],
})
export class ListTransactionsComponent implements OnChanges {
    @Input() public card: ICard;
    @Input() public reload: number = 0; // we change this input just for reload
    @ViewChild("container", {static: false}) containerRef: ElementRef; 
    public transactions: ITransaction[] = [];
    public loading: boolean = false;
    public loadingMore: boolean = false;    
    private sortBy: string = "created_at";
    private sortDir: number = -1;
    private part: number = 0;
    private chunkLength: number = 30;
    private started_at: Date = null;
    private exhausted: boolean = false;  

    constructor(
        private appService: AppService,
        private transactionRepository: TransactionRepository,
    ) {}

    get words(): IWords {return this.appService.words;}      
    get lang(): ILang {return this.appService.lang;}  
    get container(): HTMLElement {return this.containerRef.nativeElement;}
    get scrolledToBottom(): boolean {return this.container.scrollHeight - this.container.scrollTop < this.container.clientHeight + 200;}
    get canLoadMore(): boolean {return !this.loadingMore && !this.exhausted && this.scrolledToBottom;}    

    public ngOnChanges(changes: SimpleChanges): void {
        this.initTransactions();
    }

    private async initTransactions(): Promise<void> {
        try {
            this.loading = true;
            await this.appService.pause(300);
            this.part = 0;
            const filter = {card_id: this.card.id};
            // Для предотвращения дублей в бесконечной прокрутке при добавлении новых элементов после момента, когда первый кусок загружен.
            // Если сразу вставить в фильтр created: this.started_at, в серверной версии была бы проблема из-за другой таймзоны.
            this.started_at = new Date(); 
            const chunk = await this.transactionRepository.loadChunk(this.part, this.chunkLength, this.sortBy, this.sortDir, filter);     
            this.transactions = chunk.data;
            this.exhausted = !chunk.elementsQuantity || this.part + 1 === chunk.pagesQuantity;  
            this.loading = false;
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    }

    public async onScroll(): Promise<void> {
        try {			            
            if (this.canLoadMore) {
				this.loadingMore = true;
				this.part++;            
                const filter = {card_id: this.card.id, created_at: this.started_at}; // здесь используем доп. фильтр
                const chunk = await this.transactionRepository.loadChunk(this.part, this.chunkLength, this.sortBy, this.sortDir, filter);  
                this.transactions = [...this.transactions, ...chunk.data];                                         
                this.exhausted = !chunk.elementsQuantity || this.part + 1 === chunk.pagesQuantity;  
				this.loadingMore = false;		                
			}			
		} catch (err) {
			this.appService.notifyError(err);
            this.loadingMore = false;
		}
    }

    public abs(v: number): number {
        return Math.abs(v);
    }
}
