<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['send']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content compact">
            <div class="b-margined-30 ta-center">{{words['send']?.['subtitle-'+card.type.currency.name]?.[lang.slug]}}</div>
            <form class="gf" (submit)="onSubmit()">
                <div class="gf-row b-margined-20">
                    <div class="gf-pv">
                        <div class="gf-pvp">{{words['send']?.['network']?.[lang.slug]}}:</div>
                        <div class="gf-pvv">{{card.type.network.name}}</div>
                    </div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="no" [(ngModel)]="ca_no" [placeholder]="words['send']?.['wallet']?.[lang.slug]" [class.error]="errors['ca_no']" (keyup)="removeNonWallet('ca_no')"></div>
                    <div *ngIf="errors['ca_no']" class="gf-error">{{words['errors']?.[errors['ca_no']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-inputwrap"><input type="number" min="0" name="value" [(ngModel)]="value" [class.error]="errors['value']" [placeholder]="words['send']?.['value']?.[lang.slug]"></div>
                    <div *ngIf="errors['value']" class="gf-error">{{words['errors']?.[errors['value']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row">
                    <div class="gf-btnwrap"><button type="submit" class="btn" [class.busy]="loading"><span>{{words['send']?.['send']?.[lang.slug]}}</span></button></div>
                </div>              
            </form>            
        </div>
    </div>
</div>
