import { Injectable } from '@angular/core';
import { ICreditCreate } from 'src/app/model/dto/credit.create.interface';
import { DataService } from '../data.service';

@Injectable()
export class CreditRepository {
    constructor(protected dataService: DataService) {}  

    public create(dto: ICreditCreate): Promise<number> {
        return new Promise((resolve, reject) => this.dataService
            .creditsCreate(dto)
            .subscribe({
                next: res => resolve(res.statusCode),
                error: err => reject(err.message),
            }));
    }
}
