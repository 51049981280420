<div class="calculator">
    <div class="value">
        <span class="num">{{moneyValue}}</span>
        <span class="unit">{{words['home']?.[activeCurrency === 0 ? 'calc-usd' : 'calc-eur']?.[lang.slug]}}</span>
        <div class="curr-switch">
            <div class="smallbtn usd" (click)="activeCurrency = 0" [class.active]="activeCurrency === 0"></div>
            <div class="smallbtn eur" (click)="activeCurrency = 1" [class.active]="activeCurrency === 1"></div>
        </div>
    </div>
    <the-slider [value]="moneyDefaultValue" (valueChange)="moneyValueChange($event)" [stepsArray]="moneyStepsArray"></the-slider>
    <div class="value second-value">
        <span class="num">{{dayValue}}</span>
        <span class="unit">{{words['home']?.['calc-days']?.[lang.slug]}}</span>
    </div>
    <the-slider [value]="daysDefaultValue" (valueChange)="daysValueChange($event)" [stepsArray]="daysStepsArray"></the-slider>
    <div class="c-total">
        <div class="c-bid">
            <span class="num">{{words['home']?.['calc-bid']?.[lang.slug]}}&nbsp;</span>
            <span class="unit">{{rate}}%</span>
        </div>
        <span class="unit">{{words['home']?.['calc-total']?.[lang.slug]}}</span>
        <span class="c-total-amount">{{total}}</span>
    </div>
</div>
