import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { ILang } from "src/app/model/entities/lang.interface";
import { ISettings } from "src/app/model/entities/settings.interface";
import { IWords } from "src/app/model/entities/words.interface";

@Component({
    selector: "the-converter",
    templateUrl: "converter.component.html",
    styleUrls: ["converter.component.scss"],
})
export class ConverterComponent implements OnInit {
    public usdValue: number = 1;
    public cryptoValue: number;
    public activeTab: number = 0;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected router: Router,
    ) {}

    get words(): IWords {return this.appService.words;}      
    get lang(): ILang {return this.appService.lang;}  
	  get settings(): ISettings {return this.appService.settings;}
	  get btcPrice(): number {return parseFloat(this.settings?.['btc-price']);}
	  get ltcPrice(): number {return parseFloat(this.settings?.['ltc-price']);}
	  get activeCurrencyPrice(): number {return this.activeTab === 0 ? this.ltcPrice : this.btcPrice}

	  public changeCurrency(value: number): void {
	      this.activeTab = value;
        this.cryptoValue = this.usdValue * this.activeCurrencyPrice;
	  }

    public ngOnInit(): void {
        this.cryptoValue = this.usdValue * this.activeCurrencyPrice;
    }

    public usdValueChange(event: number) {
        this.cryptoValue = event * this.activeCurrencyPrice;
    }

    public onPromoBtnClick(): void { 
        if (this.authService.authData) {
            this.router.navigateByUrl(`/${this.lang.slug}/account`);
        } else {
            this.appService.pRegisterActive = true;
        }
    }
}
