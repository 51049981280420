<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['send']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content compact">
            <div class="b-margined-30 ta-center">{{words['send']?.['subtitle-'+card.type.currency.name]?.[lang.slug]}}</div>
            <form class="gf" (submit)="onSubmit()">
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap">
                        <div class="selectwrap">
                            <select [(ngModel)]="mode" name="mode" (change)="onModeChanged()">
                                <option value="no">{{words['send']?.['by-no']?.[lang.slug]}}</option>
                                <option value="iban">{{words['send']?.['by-iban']?.[lang.slug]}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div *ngIf="mode === 'no'" class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" maxlength="16" name="no" [(ngModel)]="ca_no" [placeholder]="words['send']?.['no']?.[lang.slug]" [class.error]="errors['ca_no']" (keyup)="removeNonDigits('ca_no')"></div>
                    <div *ngIf="errors['ca_no']" class="gf-error">{{words['errors']?.[errors['ca_no']]?.[lang.slug]}}</div>
                </div>
                <div *ngIf="mode === 'iban'" class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="iban" [(ngModel)]="ca_iban" [placeholder]="words['send']?.['iban']?.[lang.slug]" [class.error]="errors['ca_iban']"></div>
                    <div *ngIf="errors['ca_iban']" class="gf-error">{{words['errors']?.[errors['ca_iban']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="lastname" [(ngModel)]="lastname" (keyup)="removeNonLatin('lastname')" [class.error]="errors['lastname']" [placeholder]="words['send']?.['lastname']?.[lang.slug]"></div>
                    <div *ngIf="errors['lastname']" class="gf-error">{{words['errors']?.[errors['lastname']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="firstname" [(ngModel)]="firstname" (keyup)="removeNonLatin('firstname')" [class.error]="errors['firstname']"  [placeholder]="words['send']?.['firstname']?.[lang.slug]"></div>
                    <div *ngIf="errors['firstname']" class="gf-error">{{words['errors']?.[errors['firstname']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="middlename" [(ngModel)]="middlename" (keyup)="removeNonLatin('middlename')" [class.error]="errors['middlename']" [placeholder]="words['send']?.['middlename']?.[lang.slug]"></div>
                    <div *ngIf="errors['middlename']" class="gf-error">{{words['errors']?.[errors['middlename']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-inputwrap"><input type="number" min="0" name="value" [(ngModel)]="value" [class.error]="errors['value']" [placeholder]="words['send']?.['value']?.[lang.slug]"></div>
                    <div *ngIf="errors['value']" class="gf-error">{{words['errors']?.[errors['value']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row">
                    <div class="gf-btnwrap"><button type="submit" class="btn" [class.busy]="loading"><span>{{words['send']?.['send']?.[lang.slug]}}</span></button></div>
                </div>              
            </form>            
        </div>
    </div>
</div>
