<div class="transactions">
    <div class="t-head">{{words['account']?.['history']?.[lang.slug]}}</div>
    <div class="t-list">
        <div class="tl-content bscroll-v" #container (scroll)="onScroll()">
            <div class="tl-item" *ngFor="let t of transactions">
                <div class="tli-top">
                    <div class="tli-no">{{t.ca_no || t.ca_iban}}</div>
                    <div class="tli-value"><span [class.positive]="t.value > 0" [class.negative]="t.value < 0">{{card.type.currency.sign}}{{abs(t.value)}}</span></div>
                </div>
                <div class="tli-bottom">{{t.description[lang.slug]}} <span class="tli-status status-{{t.status}}">{{words['account']?.['status-'+t.status]?.[lang.slug]}}</span></div>
            </div>
        </div>  
        <div class="tl-loading" [class.active]="loading"></div>      
    </div>
</div>
