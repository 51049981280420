<footer name="footer" #footer>
    <div class="f-content">
        <div class="f-left">
            <div class="f-top">
                <img src="/assets/images/logo.png">
                <nav class="h-menu">
                    <ul>
                        <li><a (click)="onMenuClick('calculator')">{{words['header']?.['calculator']?.[lang.slug]}}</a></li>
                        <li><a (click)="onMenuClick('crypto')">{{words['header']?.['crypto']?.[lang.slug]}}</a></li>
                    </ul>
                </nav>
            </div>
            <div class="f-bottom">
                <div class="f-label">{{words['footer']?.['copyright']?.[lang.slug]}}</div>
                <div class="f-privacy-policy">
                    <a href="{{staticUrl}}/ufiles/{{ufiles?.['fees'].fileurl}}" target="_blank">{{words['footer']?.['privacy']?.[lang.slug]}}</a>
                    <a href="{{staticUrl}}/ufiles/{{ufiles?.['terms'].fileurl}}" target="_blank">{{words['footer']?.['policy']?.[lang.slug]}}</a>
                </div>
            </div>
        </div>
        <div class="f-right">
            <div>
                <p class="f-label">{{words['footer']?.['number-label']?.[lang.slug]}}</p>
                <a [href]="words['footer']?.['wa-link']?.[lang.slug]" target="_blank" class="f-value">
                  <p class="f-value">{{words['footer']?.['number']?.[lang.slug]}}</p>
                </a>
            </div>
            <div>
                <p class="f-label">{{words['footer']?.['tg-label']?.[lang.slug]}}</p>
                <a [href]="words['footer']?.['tg-link']?.[lang.slug]" target="_blank" class="f-value">
                  <p class="f-value">{{words['footer']?.['tg']?.[lang.slug]}}</p>
                </a>
            </div>
            <div class="f-value f-address">{{words['footer']?.['address']?.[lang.slug]}}</div>
        </div>
    </div>
    <div class="info">
        <p>{{words['footer']?.['info1']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info2']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info3']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info4']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info5']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info6']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info7']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info8']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info9']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info10']?.[lang.slug]}}</p>
        <p>{{words['footer']?.['info11']?.[lang.slug]}}</p>
        <ul>
            <li>{{words['footer']?.['info-item1']?.[lang.slug]}}</li>
            <li>{{words['footer']?.['info-item2']?.[lang.slug]}}</li>
            <li>{{words['footer']?.['info-item3']?.[lang.slug]}}</li>
            <li>{{words['footer']?.['info-item4']?.[lang.slug]}}</li>
            <li>{{words['footer']?.['info-item5']?.[lang.slug]}}</li>
            <li>{{words['footer']?.['info-item6']?.[lang.slug]}}</li>
        </ul>
        <p>{{words['footer']?.['info12']?.[lang.slug]}}</p>
    </div>

    <div class="f-content-mob">
        <div class="f-top">
            <div class="f-contacts">
                <div>
                    <p class="f-label">{{words['footer']?.['number-label']?.[lang.slug]}}</p>
                    <a [href]="words['footer']?.['tg-link']?.[lang.slug]" target="_blank" class="f-value">
                      <p class="f-value">{{words['footer']?.['number']?.[lang.slug]}}</p>
                    </a>
                </div>
                <div>
                    <p class="f-label">{{words['footer']?.['tg-label']?.[lang.slug]}}</p>
                    <a [href]="words['footer']?.['tg-link']?.[lang.slug]" target="_blank" class="f-value">
                      <p class="f-value">{{words['footer']?.['tg']?.[lang.slug]}}</p>
                    </a>
                </div>
                <div>
                    <div class="f-value f-address">{{words['footer']?.['address']?.[lang.slug]}}</div>
                </div>
            </div>
        </div>
        <div class="f-bottom">
            <div class="f-logo"><img src="/assets/images/logo.png"></div>
            <nav class="h-menu">
                <ul>
                    <li><a (click)="onMenuClick('calculator')">{{words['header']?.['calculator']?.[lang.slug]}}</a></li>
                    <li><a (click)="onMenuClick('crypto')">{{words['header']?.['crypto']?.[lang.slug]}}</a></li>
                </ul>
            </nav>
            <div class="f-privacy-policy">
                <a href="{{staticUrl}}/ufiles/{{ufiles?.['fees'].fileurl}}" target="_blank">{{words['footer']?.['privacy']?.[lang.slug]}}</a>
                <a href="{{staticUrl}}/ufiles/{{ufiles?.['terms'].fileurl}}" target="_blank">{{words['footer']?.['policy']?.[lang.slug]}}</a>
            </div>
            <div class="f-label">{{words['footer']?.['copyright']?.[lang.slug]}}</div>
        </div>

        <div class="info-mob">
            <p>{{words['footer']?.['info1']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info2']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info3']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info4']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info5']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info6']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info7']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info8']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info9']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info10']?.[lang.slug]}}</p>
            <p>{{words['footer']?.['info11']?.[lang.slug]}}</p>
            <ul>
                <li>{{words['footer']?.['info-item1']?.[lang.slug]}}</li>
                <li>{{words['footer']?.['info-item2']?.[lang.slug]}}</li>
                <li>{{words['footer']?.['info-item3']?.[lang.slug]}}</li>
                <li>{{words['footer']?.['info-item4']?.[lang.slug]}}</li>
                <li>{{words['footer']?.['info-item5']?.[lang.slug]}}</li>
                <li>{{words['footer']?.['info-item6']?.[lang.slug]}}</li>
            </ul>
            <p>{{words['footer']?.['info12']?.[lang.slug]}}</p>
        </div>
    </div>
</footer>
