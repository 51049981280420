<div class="period">
    <div class="p-scalewrap">
        <div class="p-scale">
            <div class="p-section active"></div>
            <div class="p-section" [class.active]="value >= 30"></div>
            <div class="p-section" [class.active]="value >= 60"></div>
            <div class="p-section" [class.active]="value === 180"></div>            
        </div>
        <div class="p-handles">
            <div class="p-handle active disabled"><span>0 {{words['common']?.['days']?.[lang.slug]}}</span></div>
            <div class="p-handle active" [class.disabled]="value === 10" (click)="onSelect(10)"><span>10 {{words['common']?.['days']?.[lang.slug]}}</span></div>
            <div class="p-handle" [class.active]="value >= 30" [class.disabled]="value === 30" (click)="onSelect(30)"><span>30 {{words['common']?.['days']?.[lang.slug]}}</span></div>
            <div class="p-handle" [class.active]="value >= 60" [class.disabled]="value === 60" (click)="onSelect(60)"><span>60 {{words['common']?.['days']?.[lang.slug]}}</span></div>
            <div class="p-handle" [class.active]="value === 180" [class.disabled]="value === 180" (click)="onSelect(180)"><span>180 {{words['common']?.['days']?.[lang.slug]}}</span></div>
        </div>
    </div>
</div>