<div class="dropdown">
    <div class="dropdown-toggle" [class.dropdown-toggle-active]="isDropdownOpen" (click)="toggleDropdown()">
        <a class="active">
            <img [src]="getLangImg(lang)">
        </a>
    </div>
    <ul class="dropdown-menu" [class.show]="isDropdownOpen">
        <li *ngFor="let l of langs">
            <a [routerLink]="getLangLink(l)" [class.active]="lang.id === l.id" (click)="toggleDropdown()">
                <img [src]="getLangImg(l)">
            </a>
        </li>
    </ul>                
</div>

