import { Component, Input } from "@angular/core";
import { ICard } from "src/app/model/entities/card.interface";
import { TerminalComponent } from "../terminal.component";

@Component({
    selector: "card-terminal",
    templateUrl: "card-terminal.component.html",
    styleUrls: ["../terminal.component.scss"],
})
export class CardTerminalComponent extends TerminalComponent {
    @Input() public card: ICard;
}
