import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";

@Component({
    selector: "the-slider",
    templateUrl: "slider.component.html",
    styleUrls: ["slider.component.scss"],
})
export class SliderComponent implements OnInit {
    @Input() public value: number;
    @Input() public stepsArray: any[];
    @Output() public valueChange: EventEmitter<number> = new EventEmitter();
    public startValue: number = 0;
    public options: Options = {
        showTicksValues: true,
    };

    public ngOnInit(): void {
        this.options = {
            floor: 0,
            ceil: 0,
            minLimit: 0,
            showTicksValues: true,
            showSelectionBar: true,
            stepsArray: this.stepsArray,
        };
    }

    public sliderValueChange(event: any) {
        this.valueChange.emit(event);
    }
}
