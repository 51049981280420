import { Component } from "@angular/core";
import { PopupReceiveComponent } from "../popup-receive.component";

@Component({
    selector: "popup-receive-bank",
    templateUrl: "popup-receive-bank.component.html",
    styleUrls: [
        "../../popup.component.scss",
        "../popup-receive.component.scss",
        "popup-receive-bank.component.scss",
    ],
})
export class PopupReceiveBankComponent extends PopupReceiveComponent {
    get userFullname(): string {return [this.user.lastname, this.user.firstname, this.user.middlename].join(" ");}
    get cardExp(): string {return `${this.card.exp_month} / ${this.card.exp_year}`;}
}