import { Component, Input } from "@angular/core";
import { ICard } from "src/app/model/entities/card.interface";

@Component({
    selector: "the-card",
    templateUrl: "card.component.html",
    styleUrls: ["card.component.scss"],
})
export class CardComponent {
    @Input() public card: ICard;
    public showCvv: boolean = false;

    public toggleCvv(): void {
        this.showCvv = !this.showCvv;
    }
}
