import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { Error404Page } from "./404/error404.page";

let routes = RouterModule.forChild ([            
	{path: "404", component: Error404Page},	
]);

@NgModule({	
    imports: [	
		CommonModule,
		RouterModule,              
        routes,		
	],
	declarations: [
		Error404Page,
	],    		    
})
export class ErrorsModule {}