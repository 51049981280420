import { ICurrency } from "./currency.interface";
import { INetwork } from "./network.interface";

export enum CardSubtype {
    Bank = "bank",
    Crypto = "crypto",
}

export interface ICardtype {
    readonly id: number;
    readonly name: string;  
    readonly img: string;  
    readonly subtype: CardSubtype;
    // relations
    readonly currency: ICurrency;
    readonly network: INetwork;
}