<div class="ct">
    <div class="ct-card"><the-card [card]="card"></the-card></div>
    <div class="ct-btns">
        <div class="ct-btnwrap"><button type="button" class="btn light" (click)="onCredit()">{{words['account']?.['credit']?.[lang.slug]}}</button></div>
        <div class="ct-btnwrap"><button type="button" class="btn" (click)="onSend()">{{words['account']?.['send']?.[lang.slug]}}</button></div>
        <div class="ct-btnwrap"><button type="button" class="btn contrast" (click)="onReceive()">{{words['account']?.['receive']?.[lang.slug]}}</button></div>
    </div>
    <div class="ct-transactions">
        <list-transactions [card]="card" [reload]="reloadInitiator"></list-transactions>
    </div>
    <ng-container *ngIf="card.type.subtype === 'bank'">
        <popup-send-bank [card]="card" [(active)]="pSendBankActive" (sent)="onTransactionSent()"></popup-send-bank>
        <popup-receive-bank [card]="card" [(active)]="pReceiveBankActive"></popup-receive-bank>
    </ng-container>
    <ng-container *ngIf="card.type.subtype === 'crypto'">
        <popup-send-crypto [card]="card" [(active)]="pSendCryptoActive" (sent)="onTransactionSent()"></popup-send-crypto>
        <popup-receive-crypto [card]="card" [(active)]="pReceiveCryptoActive"></popup-receive-crypto>
    </ng-container>
    <popup-credit [card]="card" [(active)]="pCreditActive"></popup-credit>
</div>
