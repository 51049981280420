<div class="panel-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="panel" [class.active]="active">
    <div class="notifications">
        <div class="n-head">{{words['notifications']?.['title']?.[lang.slug]}}</div>
        <div class="n-wrap">
            <ng-container *ngIf="!notifications.length">
                <div class="n-empty">{{words['notifications']?.['empty']?.[lang.slug]}}</div>
            </ng-container>
            <ng-container *ngIf="notifications.length">
                <div class="n-main bscroll-v" #container (scroll)="onScroll()">
                    <div class="n-item" *ngFor="let n of notifications">
                        <div class="n-date">{{n.date[lang.slug]}}</div>
                        <div class="n-content" [innerHTML]="n.content[lang.slug]"></div>
                    </div>
                </div>
            </ng-container>            
        </div>
    </div>
</div>