import { Component, OnInit } from "@angular/core";
import { SimplePage } from "../simple.page";

@Component({
    selector: "static-page",
    templateUrl: "static.page.html",
})
export class StaticPage extends SimplePage implements OnInit {
    public async ngOnInit(): Promise<void> { 
        this.route.params.subscribe(async p => {
            await this.initPage(p["page"]);     
            this.initSEO();
        });
    }

    protected async initPage(slug: string): Promise<void> {
        if (slug !== this.page?.slug) {
            await super.initPage(slug);
        }
    }
}
