<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['receive']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content compact">
            <div class="creds" *ngIf="user">
                <div class="c-item">
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['wallet']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(card.no)"></div>
                        </div>
                        <div class="ci-value">{{card.no}}</div>
                    </div>                     
                </div>
            </div>      
        </div>
    </div>
</div>
