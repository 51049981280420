import { Component } from "@angular/core";
import { PanelComponent } from "../panel.component";

@Component({
    selector: "panel-logout",
    templateUrl: "panel-logout.component.html",
    styleUrls: [
        "../panel.component.scss",
        "panel-logout.component.scss",
    ],
})
export class PanelLogoutComponent extends PanelComponent {
    
}