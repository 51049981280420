import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { ILang } from "src/app/model/entities/lang.interface";
import { IWords } from "src/app/model/entities/words.interface";
import { AppService } from "../../services/app.service";

@Directive()
export abstract class PanelComponent {
    @Input() public active: boolean; 
    @Output() private activeChange: EventEmitter<boolean> = new EventEmitter();

    constructor(protected appService: AppService) {}

    get words(): IWords {return this.appService.words;}      
    get lang(): ILang {return this.appService.lang;}  

    public onClose(): void {
        this.activeChange.emit(false);
    }
}
