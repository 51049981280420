<div class="mm" [class.active]="active">
    <div class="mm-content">
        <nav class="mm-menu">
            <ul>
                <li><a (click)="onMenuClick('calculator')">{{words['header']?.['calculator']?.[lang.slug]}}</a></li>
                <li><a (click)="onMenuClick('crypto')">{{words['header']?.['crypto']?.[lang.slug]}}</a></li>
            </ul>
        </nav>
        <div class="mm-langs">
            <ul>
                <li *ngFor="let l of langs">
                    <a [routerLink]="getLangLink(l)" [class.active]="lang.id === l.id">
                        <img [src]="getLangImg(l)">
                    </a>
                </li>
            </ul>                
        </div>
    </div>    
</div>
