<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-content compact">
            <div class="popup-icon b-margined-30"><img src="/assets/images/done.svg" alt="done"></div>   
            <div class="popup-txt ta-center b-margined-30">{{words['credit']?.['done']?.[lang.slug]}}</div>      
            <div class="popup-btn"><button class="btn" (click)="onClose()">{{words['common']?.['close']?.[lang.slug]}}</button></div>
        </div>
    </div>
</div>
