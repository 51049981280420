<div class="content">
    <h1>{{h1}}</h1>
    <div class="notifications" *ngIf="notificationsReady">
        <ng-container *ngIf="!notifications.length">
            {{words['notifications']?.['empty']?.[lang.slug]}}
        </ng-container>
        <ng-container *ngIf="notifications.length">
            <div class="n-item" *ngFor="let n of notifications">
                <div class="n-date">{{n.date[lang.slug]}}</div>
                <div class="n-content" [innerHTML]="n.content[lang.slug]"></div>
            </div>
        </ng-container>            
    </div>
</div>