<div class="panel-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="panel" [class.active]="active">
    <div class="logout">
        <div class="l-txt">{{words['logout']?.['sure']?.[lang.slug]}}</div>  
        <div class="l-btns">
            <div><button class="btn danger" routerLink="/{{lang.slug}}/account/logout" (click)="onClose()"><span>{{words['logout']?.['logout']?.[lang.slug]}}</span></button></div>
            <div><button class="btn neutral" (click)="onClose()"><span>{{words['logout']?.['cancel']?.[lang.slug]}}</span></button></div>
        </div>
    </div>
</div>
