import { Injectable } from '@angular/core';
import { IUfiles } from 'src/app/model/entities/ufile.interface';
import { DataService } from '../data.service';

@Injectable()
export class UfileRepository {
    constructor(protected dataService: DataService) {}
    
    public loadAll(): Promise<IUfiles> {
        return new Promise((resolve, reject) => 
            this.dataService
                .ufilesAll()
                .subscribe({
                    next: res => res.statusCode === 200 ? resolve(res.data) : reject(res.error), 
                    error: err => reject(err.message),
                }));
    }
}
