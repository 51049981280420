import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/common/components/components.module";
import { ConverterComponent } from "./components/converter/converter.component";
import { ListPaysystemsComponent } from "./components/list-paysystems/list-paysystems.component";
import { HomePage } from "./pages/home.page";

@NgModule({	
    imports: [	
		CommonModule,
		RouterModule,
    FormsModule,
		ComponentsModule,
	],
	declarations: [
		HomePage,
		ListPaysystemsComponent,
		ConverterComponent,
	],    		    
})
export class HomeModule {}
