import { Component, HostListener, OnInit } from "@angular/core";
import { ILang } from "src/app/model/entities/lang.interface";
import { AppService } from "../../services/app.service";
import { cfg } from "src/app/config";

@Component({
    selector: "lang-dropdown",
    templateUrl: "lang-dropdown.component.html",
    styleUrls: ["lang-dropdown.component.scss"],
})
export class LangDropdown implements OnInit {
    public isDropdownOpen = false;
    public selectedOption: string | undefined;
    private isFocusInsideComponent: boolean = false;
    private isComponentClicked: boolean = false;

    constructor(
        private appService: AppService,
    ) {}

    get langs(): ILang[] {return this.appService.langs;}
    get lang(): ILang {return this.appService.lang;}
    get staticUrl(): string {return cfg.staticUrl;}

    @HostListener('click')
    clickInside() {
        this.isFocusInsideComponent = true;
        this.isComponentClicked = true;
    }

    @HostListener('document:click')
    clickOutside(): void {
        if (!this.isFocusInsideComponent && this.isComponentClicked) {
            this.isDropdownOpen = false;
            this.isComponentClicked = false;
        }
        this.isFocusInsideComponent = false;
    }

    ngOnInit() {}

    public toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    public selectOption(option: string) {
        this.selectedOption = option;
    }

    public getLangLink(lang: ILang): string {
        return this.appService.getLangLink(lang);
    }
    
    public getLangImg(lang: ILang): string {
        return `${this.staticUrl}/images/langs/${lang.img}`;
    }
}
