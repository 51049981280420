import { Component } from "@angular/core";
import { PopupReceiveComponent } from "../popup-receive.component";

@Component({
    selector: "popup-receive-crypto",
    templateUrl: "popup-receive-crypto.component.html",
    styleUrls: [
        "../../popup.component.scss",
        "../popup-receive.component.scss",
        "popup-receive-crypto.component.scss",
    ],
})
export class PopupReceiveCryptoComponent extends PopupReceiveComponent {}
