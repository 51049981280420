import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { PageRepository } from "src/app/common/services/repositories/page.repository";
import { SimplePage } from "../../simple.page";

@Component({
    selector: "home-page",
    templateUrl: "home.page.html",
    styleUrls: ["home.page.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class HomePage extends SimplePage implements OnInit {
    public btcPrice: number = 15231;
    public ltcPrice: number = 81;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected pageRepository: PageRepository,
        protected route: ActivatedRoute,
        protected router: Router,
    ) 
    {
        super(appService, pageRepository, route, router);
    }

    public async ngOnInit(): Promise<void> {
        this.initScroll(); 
        await this.initPage('home');        
        this.route.params.subscribe(p => this.initSEO());  
    }

    public scrollTo(blockName: string): void {
        this.appService.scrollTo(blockName);
    }

    public onPromoBtnClick(): void { 
        if (this.authService.authData) {
            this.router.navigateByUrl(`/${this.lang.slug}/account`);
        } else {
            this.appService.pRegisterActive = true;
        }
    }
    
    public onLoginBtnClick(): void {
        this.appService.pLoginActive = true;
    }
}
