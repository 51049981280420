<div class="ct" *ngIf="cards.length">
    <div class="ct-card" [class.padded]="cards.length === 1">
        <cards-slider [cards]="cards" (currentChange)="current = $event"></cards-slider>
    </div>
    <h2 class="ct-type-title">{{card.type.name}}</h2>
    <div class="content">
        <div class="ct-btns padded">
            <ng-container *ngIf="card.type.subtype === 'bank'">
                <!-- <div class="ct-btnwrap"> -->
                <!--     <div class="ct-label">{{words['account']?.['credit-label']?.[lang.slug]}}</div> -->
                <!--     <button type="button" class="btn light" (click)="onCredit()"><span>{{words['account']?.['credit']?.[lang.slug]}}</span></button> -->
                <!-- </div> -->
                <div class="ct-btnwrap">
                    <div class="ct-label">{{words['account']?.['send-bank-label']?.[lang.slug]}}</div>
                    <button type="button" class="btn" (click)="onSend()"><span>{{words['account']?.['send']?.[lang.slug]}}</span></button>
                </div>
                <div class="ct-btnwrap">
                    <div class="ct-label">{{words['account']?.['receive-label']?.[lang.slug]}}</div>
                    <button type="button" class="btn outline" (click)="onReceive()"><span>{{words['account']?.['receive']?.[lang.slug]}}</span></button>
                </div>
            </ng-container>
            <ng-container *ngIf="card.type.subtype === 'crypto'">
                <!-- <div class="ct-btnwrap" [style.justify-content]="'flex-start'"> -->
                <!--     <div class="ct-label">{{words['account']?.['credit-label']?.[lang.slug]}}</div> -->
                <!--     <button type="button" class="btn light" (click)="onCredit()"><span>{{words['account']?.['credit']?.[lang.slug]}}</span></button> -->
                <!-- </div> -->

                <div class="ct-btnwrap" [style.justify-content]="'flex-start'">
                    <form class="gf" (submit)="onSubmit()">
                        <div class="gf-row" [style.flex-direction]="'row'">
                            <div class="ct-label">{{words['account']?.['send-crypto-label']?.[lang.slug]}}</div>
                            <div class="gf-pv">
                                <div class="gf-pvp">{{words['send']?.['network']?.[lang.slug]}} -</div>
                                <div class="gf-pvv">&nbsp;{{card.type.network.name}}</div>
                            </div>
                        </div>
                        <div class="gf-row">
                            <div class="gf-inputwrap"><input type="text" name="no" [(ngModel)]="ca_no" [placeholder]="words['send']?.['wallet']?.[lang.slug]" [class.error]="errors['ca_no']" (keyup)="removeNonWallet('ca_no')"></div>
                            <div *ngIf="errors['ca_no']" class="gf-error">{{words['errors']?.[errors['ca_no']]?.[lang.slug]}}</div>
                        </div>
                        <div class="gf-row">
                            <div class="gf-inputwrap"><input type="number" min="0" name="value" [(ngModel)]="value" [class.error]="errors['value']" [placeholder]="words['send']?.['value']?.[lang.slug]"></div>
                            <div *ngIf="errors['value']" class="gf-error">{{words['errors']?.[errors['value']]?.[lang.slug]}}</div>
                        </div>
                        <div class="gf-row">
                            <div class="gf-btnwrap"><button type="submit" class="btn" [class.busy]="loading"><span>{{words['send']?.['send']?.[lang.slug]}}</span></button></div>
                        </div>
                    </form>
                </div>
                <div class="ct-btnwrap" [style.justify-content]="'flex-start'">
                    <div class="ct-label">{{words['account']?.['balance-label']?.[lang.slug]}}</div>
                    <div class="c-item">
                        <div class="ci-content">
                            <div class="ci-value">{{card.no}}</div>
                            <div class="ci-btn" (click)="copy(card.no)"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="ct-transactions padded">
            <list-transactions [card]="card" [reload]="reloadInitiator"></list-transactions>
        </div>
    </div>
    <ng-container *ngIf="card.type.subtype === 'bank'">
        <popup-send-bank [card]="card" [(active)]="pSendBankActive" (sent)="onTransactionSent()"></popup-send-bank>
        <popup-receive-bank [card]="card" [(active)]="pReceiveBankActive"></popup-receive-bank>
    </ng-container>
    <ng-container *ngIf="card.type.subtype === 'crypto'">
        <popup-send-crypto [card]="card" [(active)]="pSendCryptoActive" (sent)="onTransactionSent()"></popup-send-crypto>
        <popup-receive-crypto [card]="card" [(active)]="pReceiveCryptoActive"></popup-receive-crypto>
    </ng-container>
    <popup-credit [card]="card" [(active)]="pCreditActive"></popup-credit>
</div>
