<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-content simple">
            <div class="popup-txt ta-center b-margined-30">{{words['logout']?.['sure']?.[lang.slug]}}</div>  
            <div class="popup-2btns">
                <div><a class="btn danger" routerLink="/{{lang.slug}}/account/logout" (click)="onClose()">{{words['logout']?.['logout']?.[lang.slug]}}</a></div>
                <div><a class="btn neutral" (click)="onClose()">{{words['logout']?.['cancel']?.[lang.slug]}}</a></div>
            </div>
        </div>
    </div>
</div>
