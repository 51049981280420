import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppRouteReuseStrategy } from './app.routereusestrategy';
import { ComponentsModule } from './common/components/components.module';
import { ServicesModule } from './common/services/services.module';
import { HomeModule } from './pages/home/home.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        HttpClientModule,
        AppRoutingModule,
        ComponentsModule,
        HomeModule,
        ServicesModule,
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
