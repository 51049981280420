<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['login']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content compact">
            <form class="gf" (submit)="onSubmit()">
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="email" name="email" [(ngModel)]="email" [placeholder]="words['login']?.['email']?.[lang.slug]" [class.error]="errors['email']"></div>
                    <div *ngIf="errors['email']" class="gf-error">{{words['errors']?.[errors['email']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-inputwrap"><input type="password" name="password" [(ngModel)]="password" [placeholder]="words['login']?.['password']?.[lang.slug]" [class.error]="errors['password']"></div>
                    <div *ngIf="errors['password']" class="gf-error">{{words['errors']?.[errors['password']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-btnwrap"><button type="submit" class="btn" [class.busy]="loading"><span>{{words['login']?.['login']?.[lang.slug]}}</span></button></div>
                    <div *ngIf="errorDenied" class="gf-error ta-center">{{words['errors']?.['denied']?.[lang.slug]}}</div>
                </div>
                <div class="gf-row">
                    <div class="gf-note ta-center">{{words['login']?.['not-yet']?.[lang.slug]}} <strong><a (click)="register()">{{words['login']?.['register']?.[lang.slug]}}</a></strong></div>
                </div>                
            </form>            
        </div>
    </div>
</div>
