import { Component, Input } from "@angular/core";
import { PopupComponent } from "../popup.component";
import { ICard } from "src/app/model/entities/card.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { TPeriod } from "../../period/period.type";
import { ICreditCreate } from "src/app/model/dto/credit.create.interface";
import { AppService } from "src/app/common/services/app.service";
import { CreditRepository } from "src/app/common/services/repositories/credit.repository";

@Component({
    selector: "popup-credit",
    templateUrl: "popup-credit.component.html",
    styleUrls: [
        "../../../styles/forms.scss",
        "../popup.component.scss",
    ],
})
export class PopupCreditComponent extends PopupComponent {
    @Input() card: ICard;
    public amount: number = 0;
    public period: TPeriod = 10;
    public errors: IKeyValue<string> = {};
    public loading: boolean = false;

    constructor(
        protected appService: AppService,
        protected creditRepository: CreditRepository,
    ) 
    {
        super(appService);
    }

    public async onSubmit(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            this.loading = true;
            const dto: ICreditCreate = {card_id: this.card.id, amount: this.amount, period: this.period};
            const statusCode = await this.creditRepository.create(dto);
            this.loading = false;
            this.onClose();
            await this.appService.pause(500); 

            if (statusCode === 201) {
                this.reset();
                this.appService.pCreditedActive = true;
            } else {
                this.appService.notifyError(this.words['errors']?.['common']?.[this.lang.slug]);
            } 
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    } 

    private reset(): void {
        this.amount = 0;
        this.period = 10;
    }

    private validate(): boolean {
        let error = false;

        if (!this.amount || this.amount < 0) {
            this.errors["amount"] = "amount";
            error = true;
        } else {
            this.errors["amount"] = null;
        }

        return !error;
    }
}